import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Typography } from "@material-ui/core"
import Layout from "../components/layout/layout-home"
import TitledContentWrapper from "../components/layout/titled-content-wrapper"
import { makeStyles } from "@material-ui/core/styles"
import erintetti1 from "../documents/Erintetti_tajekoztatas_Progen_adatvedelmi_incidens_20220914.pdf"
import erintetti2 from "../documents/erintetti_tajekoztatas_Progen_adatvedelmi_incidens_20220816_volt_munkavallalok.pdf"
import d20 from "../documents/d20Yearbook_Consent form_20220825_HUN_ENG.pdf"
import noticeOnRecord from "../documents/Notice_on_the_recording_of_project_meetings.pdf"
import tajekoztatoProjectRogzites from "../documents/tajekoztato_projektmegbeszelesek_rogziteserol.pdf"
import surveillanceEn from "../documents/Information_on_Electronic_Surveillance_System.pdf"
import surveillanceHu from "../documents/tajekoztato_elektronikus_megfigyelorendszerrol.pdf"
import noticeWhistleBlowing from "../documents/notice_whistleblowing.pdf"
import privacy from "../documents/privacy_notice_on_the_processing_of_customer_data_marketing_2024_02_16_ENG.pdf"
import tajekoztato from "../documents/tajekoztato_ugyfeladatok_kezeleserol_Marketing_2024_02_16_HUN.pdf"

const useStyles = makeStyles(theme => ({
  content: {
    paddingBottom: "50px",
    maxWidth: "1000px",
    margin: "auto",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "16px",
      paddingRight: "16px",
    },
  },
  link: {
    color: "#f26522",
  },
}))

const PrivacyPolicyPage = () => {
  const classes = useStyles()

  const data = useStaticQuery(graphql`
    {
      strapi {
        privacyPolicyPage {
          Content
          Title
        }
      }
    }
  `)

  return (
    <Layout>
      <TitledContentWrapper title="GDPR">
        <Typography variant="body1" className={classes.content}>
          {/* <ReactMarkdown source={data.strapi.privacyPolicyPage.Content} /> */}
          <h4>Privacy notice whistleblowing of abuse</h4>
          <p>
            <a
              className={classes.link}
              href="/documents/notice_whistleblowing.pdf"
              key="/documents/notice_whistleblowing.pdf"
              target="_blank"
              rel="noreferrer"
            >
              Privacy notice whistleblowing of abuse
            </a>
          </p>
          <h4>D20 YEARBOOK CONSENT FORM ON THE USE OF PHOTOGRAPHS</h4>
          <p>
            <a
              className={classes.link}
              href={d20}
              key={d20}
              target="_blank"
              rel="noreferrer"
            >
              D20 Yearbook Consent Form on the use of photographs / D20 Évkönyv
              hozzájáruló nyilatkozat képek felhasználásához
            </a>
          </p>
          <h4>INFORMATION ON ELECTRONIC SURVEILLANCE</h4>
          <p>
            <a
              className={classes.link}
              href={surveillanceEn}
              key={surveillanceEn}
              target="_blank"
              rel="noreferrer"
            >
              Information on electronic surveillance
            </a>
          </p>
          <p>
            <a
              className={classes.link}
              href={surveillanceHu}
              key={surveillanceHu}
              target="_blank"
              rel="noreferrer"
            >
              Tájékoztató elektronikus megfigyelőrendszerről
            </a>
          </p>
          <h4>NOTICE ON THE RECORDING OF PROJECT MEETINGS</h4>
          <p>
            <a
              className={classes.link}
              href={noticeOnRecord}
              key={noticeOnRecord}
              target="_blank"
              rel="noreferrer"
            >
              Notice on the recording of project meetings
            </a>
          </p>
          <p>
            <a
              className={classes.link}
              href={tajekoztatoProjectRogzites}
              key={tajekoztatoProjectRogzites}
              target="_blank"
              rel="noreferrer"
            >
              Tájékoztató Projekt Megbeszélések Rögzítéséről
            </a>
          </p>
          <h4>
            Information about a data breach / Tájékoztató adatvédelmi
            incidensről
          </h4>
          <p>
            <a
              className={classes.link}
              href={erintetti1}
              key={erintetti1}
              target="_blank"
              rel="noreferrer"
            >
              Further information about data breach 14/09/2022
            </a>
          </p>
          <p>
            <a
              className={classes.link}
              href={erintetti2}
              key={erintetti2}
              target="_blank"
              rel="noreferrer"
            >
              További tájékoztató adatvédelmi incidensről 2022.09.14.
            </a>
          </p>
          <h4>
            Adatkezelési tájékoztató ügyféladatok kezeléséről / Privacy Notice
            on the processing of customer data
          </h4>
          <p>
            <a
              className={classes.link}
              href={tajekoztato}
              key={tajekoztato}
              target="_blank"
              rel="noreferrer"
            >
              Adatkezelési tájékoztató ügyféladatok kezeléséről 2024.02.16.
            </a>
          </p>
          <p>
            <a
              className={classes.link}
              href={privacy}
              key={privacy}
              target="_blank"
              rel="noreferrer"
            >
              Privacy Notice on the processing of customer data 16/02/2024
            </a>
          </p>
        </Typography>
      </TitledContentWrapper>
    </Layout>
  )
}

export default PrivacyPolicyPage
